import { useRoutes } from 'react-router-dom';

// routes
import AdminRoutes from './AdminRoutes';
import LoginRoutes from './LoginRoutes';
import EntrantRoutes from './EntrantRoutes';
import JudgeRoutes from './JudgeRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([AdminRoutes, EntrantRoutes, JudgeRoutes, LoginRoutes]);
}
