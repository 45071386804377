import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { ADMIN_DASHBOARD_PATH, JUDGE_DASHBOARD_PATH } from 'config';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const EntrantGuard = ({ children }) => {
    const { isLoggedIn, userType } = useAuth();
    const navigate = useNavigate();
    console.log('AuthGuard userType', userType);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login', { replace: true });
        } else if (userType === 'admin') {
            navigate(ADMIN_DASHBOARD_PATH, { replace: true });
        } else if (userType === 'judge') {
            navigate(JUDGE_DASHBOARD_PATH, { replace: true });
        }
    }, [isLoggedIn, navigate, userType]);

    return children;
};

EntrantGuard.propTypes = {
    children: PropTypes.node
};

export default EntrantGuard;
