import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import EntrantGuard from 'utils/route-guard/EntrantGuard';

// sample page routing
const EntrantDashboard = Loadable(lazy(() => import('views/dashboard/entrant')));
const EntrantEntries = Loadable(lazy(() => import('views/entrant/EntrantEntries')));
const UserSettings = Loadable(lazy(() => import('views/user-settings')));
const EntrantEntry = Loadable(lazy(() => import('views/entry')));
const PlayPage = Loadable(lazy(() => import('views/sample-page2')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/entrant',
    element: (
        <EntrantGuard>
            <MainLayout />
        </EntrantGuard>
    ),
    children: [
        {
            path: '/entrant',
            element: <EntrantDashboard />
        },
        {
            path: '/entrant/entry/list',
            element: <EntrantEntries />
        },
        {
            path: '/entrant/entry',
            element: <EntrantEntry />
        },
        // {
        //     path: '/entrant/play',
        //     element: <PlayPage />
        // },
        {
            path: '/entrant/settings',
            element: <UserSettings />
        }
    ]
};

export default MainRoutes;
