// export const API_BASE_URL = 'https://deo66wmc7c.execute-api.us-east-1.amazonaws.com/';
// export const API_BASE_URL = 'https://ox9yp0dlja.execute-api.eu-west-2.amazonaws.com/';

// export const AWS_API = {
//     poolId: 'us-east-1_uopEyXlMp',
//     appClientId: '7q3i8jt0jofcj0k028t16mt1p7'
// };
// export const AWS_API = {
//     poolId: 'eu-west-2_31wNFaWI3',
//     appClientId: '24glr7r0krn8j4vedhvla49351'
// };

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
export const BASE_PATH = '';

export const ADMIN_DASHBOARD_PATH = '/';
export const ENTRANT_DASHBOARD_PATH = '/entrant';
export const JUDGE_DASHBOARD_PATH = '/judge';

const config = {
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    // presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    presetColor: 'theme6',
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    container: false
};

export const userTypes = [
    { id: 'admin', name: 'Administrator' },
    { id: 'judge', name: 'Judge' },
    { id: 'entrant', name: 'Entrant' }
];

export const awardStatus = [
    { id: 'pending', name: 'Pending' },
    { id: 'approved', name: 'Approved for Lauch' },
    { id: 'active', name: 'Open' },
    { id: 'closed', name: 'Closed For Entries' },
    { id: 'completed', name: 'Completed' }
];

export const scoreStatus = [
    { id: 'unscored', name: 'Not Yet Scored', color: 'secondary' },
    { id: 'scored', name: 'Scores Not Submitted', color: 'orange' },
    { id: 'submitted', name: 'Scores Submitted', color: 'success' },
    { id: 'final', name: 'Scores Finalized', color: 'success' }
];
export const entryStatus = [
    {
        id: '0',
        text: 'In Progress',
        shortText: 'In Progress',
        color: 'orange',
        explainer: 'Your entry has been saved, but has not yet been submitted.'
    },
    {
        id: '1',
        text: 'Entry Submitted',
        shortText: 'Submitted',
        color: 'warning',
        explainer:
            'This is to confirm that your entry has been received, thank you. The next step in the process is that the shortlist, as determined by our judges, will be published in February. Good luck!'
    },
    {
        id: '2',
        text: 'Open for Judging',
        shortText: 'Judging',
        color: 'success',
        explainer: 'Your entry is currently being reviewed by our panel of judges.'
    },
    {
        id: '3',
        text: 'Category Closed',
        shortText: 'Closed',
        color: 'secondary',
        explainer: 'Judging in this category is now closed. You will soon be informed or the results.'
    },
    {
        id: '9',
        text: 'Error',
        shortText: 'Error',
        color: 'error',
        explainer:
            'There was an error with this entry. You should have received an email from us detailing the issue. Please can you correct the entry and re-submit.'
    }
];

export const countryList = [
    { id: 'AF', name: 'Afghanistan' },
    { id: 'AX', name: 'Åland Islands' },
    { id: 'AL', name: 'Albania' },
    { id: 'DZ', name: 'Algeria' },
    { id: 'AS', name: 'American Samoa' },
    { id: 'AD', name: 'Andorra' },
    { id: 'AO', name: 'Angola' },
    { id: 'AI', name: 'Anguilla' },
    { id: 'AQ', name: 'Antarctica' },
    { id: 'AG', name: 'Antigua and Barbuda' },
    { id: 'AR', name: 'Argentina' },
    { id: 'AM', name: 'Armenia' },
    { id: 'AW', name: 'Aruba' },
    { id: 'AU', name: 'Australia' },
    { id: 'AT', name: 'Austria' },
    { id: 'AZ', name: 'Azerbaijan' },
    { id: 'BS', name: 'Bahamas', includeThe: true },
    { id: 'BH', name: 'Bahrain' },
    { id: 'BD', name: 'Bangladesh' },
    { id: 'BB', name: 'Barbados' },
    { id: 'BY', name: 'Belarus' },
    { id: 'BE', name: 'Belgium' },
    { id: 'BZ', name: 'Belize' },
    { id: 'BJ', name: 'Benin' },
    { id: 'BM', name: 'Bermuda' },
    { id: 'BT', name: 'Bhutan' },
    { id: 'BO', name: 'Bolivia, Plurinational State of' },
    { id: 'BQ', name: 'Bonaire, Sint Eustatius and Saba' },
    { id: 'BA', name: 'Bosnia and Herzegovina' },
    { id: 'BW', name: 'Botswana' },
    { id: 'BV', name: 'Bouvet Island' },
    { id: 'BR', name: 'Brazil' },
    { id: 'IO', name: 'British Indian Ocean Territory', includeThe: true },
    { id: 'BN', name: 'Brunei Darussalam' },
    { id: 'BG', name: 'Bulgaria' },
    { id: 'BF', name: 'Burkina Faso' },
    { id: 'BI', name: 'Burundi' },
    { id: 'CV', name: 'Cabo Verde' },
    { id: 'KH', name: 'Cambodia' },
    { id: 'CM', name: 'Cameroon' },
    { id: 'CA', name: 'Canada' },
    { id: 'KY', name: 'Cayman Islands', includeThe: true },
    { id: 'CF', name: 'Central African Republic', includeThe: true },
    { id: 'TD', name: 'Chad' },
    { id: 'CL', name: 'Chile' },
    { id: 'CN', name: 'China' },
    { id: 'CX', name: 'Christmas Island' },
    { id: 'CC', name: 'Cocos (Keeling) Islands', includeThe: true },
    { id: 'CO', name: 'Colombia' },
    { id: 'KM', name: 'Comoros' },
    { id: 'CD', name: 'Congo, Democratic Republic of the' },
    { id: 'CG', name: 'Congo' },
    { id: 'CK', name: 'Cook Islands', includeThe: true },
    { id: 'CR', name: 'Costa Rica' },
    { id: 'CI', name: "Côte d'Ivoire" },
    { id: 'HR', name: 'Croatia' },
    { id: 'CU', name: 'Cuba' },
    { id: 'CW', name: 'Curaçao' },
    { id: 'CY', name: 'Cyprus' },
    { id: 'CZ', name: 'Czechia' },
    { id: 'DK', name: 'Denmark' },
    { id: 'DJ', name: 'Djibouti' },
    { id: 'DM', name: 'Dominica' },
    { id: 'DO', name: 'Dominican Republic', includeThe: true },
    { id: 'EC', name: 'Ecuador' },
    { id: 'EG', name: 'Egypt' },
    { id: 'SV', name: 'El Salvador' },
    { id: 'GQ', name: 'Equatorial Guinea' },
    { id: 'ER', name: 'Eritrea' },
    { id: 'EE', name: 'Estonia' },
    { id: 'SZ', name: 'Eswatini' },
    { id: 'ET', name: 'Ethiopia' },
    { id: 'FK', name: 'Falkland Islands (Malvinas)', includeThe: true },
    { id: 'FO', name: 'Faroe Islands', includeThe: true },
    { id: 'FJ', name: 'Fiji' },
    { id: 'FI', name: 'Finland' },
    { id: 'FR', name: 'France' },
    { id: 'GF', name: 'French Guiana' },
    { id: 'PF', name: 'French Polynesia' },
    { id: 'TF', name: 'French Southern Territories' },
    { id: 'GA', name: 'Gabon' },
    { id: 'GM', name: 'Gambia', includeThe: true },
    { id: 'GE', name: 'Georgia' },
    { id: 'DE', name: 'Germany' },
    { id: 'GH', name: 'Ghana' },
    { id: 'GI', name: 'Gibraltar' },
    { id: 'GR', name: 'Greece' },
    { id: 'GL', name: 'Greenland' },
    { id: 'GD', name: 'Grenada' },
    { id: 'GP', name: 'Guadeloupe' },
    { id: 'GU', name: 'Guam' },
    { id: 'GT', name: 'Guatemala' },
    { id: 'GG', name: 'Guernsey' },
    { id: 'GW', name: 'Guinea-Bissau' },
    { id: 'GN', name: 'Guinea' },
    { id: 'GY', name: 'Guyana' },
    { id: 'HT', name: 'Haiti' },
    { id: 'HM', name: 'Heard Island and McDonald Islands' },
    { id: 'VA', name: 'Holy See' },
    { id: 'HN', name: 'Honduras' },
    { id: 'HK', name: 'Hong Kong' },
    { id: 'HU', name: 'Hungary' },
    { id: 'IS', name: 'Iceland' },
    { id: 'IN', name: 'India' },
    { id: 'ID', name: 'Indonesia' },
    { id: 'IR', name: 'Iran, Islamic Republic of' },
    { id: 'IQ', name: 'Iraq' },
    { id: 'IE', name: 'Ireland' },
    { id: 'IM', name: 'Isle of Man', includeThe: true },
    { id: 'IL', name: 'Israel' },
    { id: 'IT', name: 'Italy' },
    { id: 'JM', name: 'Jamaica' },
    { id: 'JP', name: 'Japan' },
    { id: 'JE', name: 'Jersey' },
    { id: 'JO', name: 'Jordan' },
    { id: 'KZ', name: 'Kazakhstan' },
    { id: 'KE', name: 'Kenya' },
    { id: 'KI', name: 'Kiribati' },
    { id: 'KP', name: "Korea, Democratic People's Republic of" },
    { id: 'KR', name: 'Korea, Republic of' },
    { id: 'KW', name: 'Kuwait' },
    { id: 'KG', name: 'Kyrgyzstan' },
    { id: 'LA', name: "Lao People's Democratic Republic" },
    { id: 'LV', name: 'Latvia' },
    { id: 'LB', name: 'Lebanon' },
    { id: 'LS', name: 'Lesotho' },
    { id: 'LR', name: 'Liberia' },
    { id: 'LY', name: 'Libya' },
    { id: 'LI', name: 'Liechtenstein' },
    { id: 'LT', name: 'Lithuania' },
    { id: 'LU', name: 'Luxembourg' },
    { id: 'MO', name: 'Macao' },
    { id: 'MG', name: 'Madagascar' },
    { id: 'MW', name: 'Malawi' },
    { id: 'MY', name: 'Malaysia' },
    { id: 'MV', name: 'Maldives', includeThe: true },
    { id: 'ML', name: 'Mali' },
    { id: 'MT', name: 'Malta' },
    { id: 'MH', name: 'Marshall Islands', includeThe: true },
    { id: 'MQ', name: 'Martinique' },
    { id: 'MR', name: 'Mauritania' },
    { id: 'MU', name: 'Mauritius' },
    { id: 'YT', name: 'Mayotte' },
    { id: 'MX', name: 'Mexico' },
    { id: 'FM', name: 'Micronesia, Federated States of' },
    { id: 'MD', name: 'Moldova, Republic of' },
    { id: 'MC', name: 'Monaco' },
    { id: 'MN', name: 'Mongolia' },
    { id: 'ME', name: 'Montenegro' },
    { id: 'MS', name: 'Montserrat' },
    { id: 'MA', name: 'Morocco' },
    { id: 'MZ', name: 'Mozambique' },
    { id: 'MM', name: 'Myanmar' },
    { id: 'NA', name: 'Namibia' },
    { id: 'NR', name: 'Nauru' },
    { id: 'NP', name: 'Nepal' },
    { id: 'NL', name: 'Netherlands', includeThe: true },
    { id: 'NC', name: 'New Caledonia' },
    { id: 'NZ', name: 'New Zealand' },
    { id: 'NI', name: 'Nicaragua' },
    { id: 'NE', name: 'Niger' },
    { id: 'NG', name: 'Nigeria' },
    { id: 'NU', name: 'Niue' },
    { id: 'NF', name: 'Norfolk Island' },
    { id: 'MK', name: 'North Macedonia' },
    { id: 'MP', name: 'Northern Mariana Islands' },
    { id: 'NO', name: 'Norway' },
    { id: 'OM', name: 'Oman' },
    { id: 'PK', name: 'Pakistan' },
    { id: 'PW', name: 'Palau' },
    { id: 'PS', name: 'Palestine, State of' },
    { id: 'PA', name: 'Panama' },
    { id: 'PG', name: 'Papua New Guinea' },
    { id: 'PY', name: 'Paraguay' },
    { id: 'PE', name: 'Peru' },
    { id: 'PH', name: 'Philippines', includeThe: true },
    { id: 'PN', name: 'Pitcairn' },
    { id: 'PL', name: 'Poland' },
    { id: 'PT', name: 'Portugal' },
    { id: 'PR', name: 'Puerto Rico' },
    { id: 'QA', name: 'Qatar' },
    { id: 'RE', name: 'Réunion' },
    { id: 'RO', name: 'Romania' },
    { id: 'RU', name: 'Russian Federation', includeThe: true },
    { id: 'RW', name: 'Rwanda' },
    { id: 'BL', name: 'Saint Barthélemy' },
    { id: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha' },
    { id: 'KN', name: 'Saint Kitts and Nevis' },
    { id: 'LC', name: 'Saint Lucia' },
    { id: 'MF', name: 'Saint Martin, (French part)' },
    { id: 'PM', name: 'Saint Pierre and Miquelon' },
    { id: 'VC', name: 'Saint Vincent and the Grenadines' },
    { id: 'WS', name: 'Samoa' },
    { id: 'SM', name: 'San Marino' },
    { id: 'ST', name: 'Sao Tome and Principe' },
    { id: 'SA', name: 'Saudi Arabia' },
    { id: 'SN', name: 'Senegal' },
    { id: 'RS', name: 'Serbia' },
    { id: 'SC', name: 'Seychelles', includeThe: true },
    { id: 'SL', name: 'Sierra Leone' },
    { id: 'SG', name: 'Singapore' },
    { id: 'SX', name: 'Sint Maarten, (Dutch part)' },
    { id: 'SK', name: 'Slovakia' },
    { id: 'SI', name: 'Slovenia' },
    { id: 'SB', name: 'Solomon Islands', includeThe: true },
    { id: 'SO', name: 'Somalia' },
    { id: 'ZA', name: 'South Africa' },
    { id: 'GS', name: 'South Georgia and the South Sandwich Islands' },
    { id: 'SS', name: 'South Sudan' },
    { id: 'ES', name: 'Spain' },
    { id: 'LK', name: 'Sri Lanka' },
    { id: 'SD', name: 'Sudan' },
    { id: 'SR', name: 'Suriname' },
    { id: 'SJ', name: 'Svalbard and Jan Mayen' },
    { id: 'SE', name: 'Sweden' },
    { id: 'CH', name: 'Switzerland' },
    { id: 'SY', name: 'Syrian Arab Republic', includeThe: true },
    { id: 'TW', name: 'Taiwan, Province of China' },
    { id: 'TJ', name: 'Tajikistan' },
    { id: 'TZ', name: 'Tanzania, United Republic of' },
    { id: 'TH', name: 'Thailand' },
    { id: 'TL', name: 'Timor-Leste' },
    { id: 'TG', name: 'Togo' },
    { id: 'TK', name: 'Tokelau' },
    { id: 'TO', name: 'Tonga' },
    { id: 'TT', name: 'Trinidad and Tobago' },
    { id: 'TN', name: 'Tunisia' },
    { id: 'TR', name: 'Turkey' },
    { id: 'TM', name: 'Turkmenistan' },
    { id: 'TC', name: 'Turks and Caicos Islands', includeThe: true },
    { id: 'TV', name: 'Tuvalu' },
    { id: 'UG', name: 'Uganda' },
    { id: 'UA', name: 'Ukraine' },
    { id: 'AE', name: 'United Arab Emirates', includeThe: true },
    { id: 'GB', name: 'United Kingdom of Great Britain and Northern Ireland', includeThe: true },
    { id: 'US', name: 'United States of America', includeThe: true },
    { id: 'UM', name: 'United States Minor Outlying Islands', includeThe: true },
    { id: 'UY', name: 'Uruguay' },
    { id: 'UZ', name: 'Uzbekistan' },
    { id: 'VU', name: 'Vanuatu' },
    { id: 'VE', name: 'Venezuela, Bolivarian Republic of' },
    { id: 'VN', name: 'Viet Nam' },
    { id: 'VG', name: 'British Virgin Islands', includeThe: true },
    { id: 'VI', name: 'U.S. Virgin Islands', includeThe: true },
    { id: 'WF', name: 'Wallis and Futuna' },
    { id: 'EH', name: 'Western Sahara', includeThe: true },
    { id: 'YE', name: 'Yemen' },
    { id: 'ZM', name: 'Zambia' },
    { id: 'ZW', name: 'Zimbabwe' }
];

export default config;
