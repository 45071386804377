import admin from './admin';
import judge from './judge';
import entrant from './entrant';

// ===========================|| MENU ITEMS ||=========================== //

const menuItems = ({ userType }) => {
    const items = [];
    if (userType === 'admin') {
        items.push(admin);
    } else if (userType === 'judge') {
        items.push(judge);
    } else {
        items.push(entrant);
    }
    return { items };
};

export default menuItems;
