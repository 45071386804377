import { apiGet, apiUpdate, apiPost, apiDelete } from './apiClient';

const getEntryAPI = async ({ id, awardID }) => {
    const url = `/entry?id=${id}&awardID=${awardID}`;
    const response = await apiGet({ url });
    return response;
};

const createEntryAPI = async ({ data, isSubmit }) => {
    const url = isSubmit ? `/entry?isSubmit=true` : `/entry`;
    const response = await apiPost({ url, data });
    return response;
};

const getEntryListAPI = async () => {
    console.log('calling axios get entrys list');
    const response = await apiGet({ url: `/entry/list` });
    return response;
};

const adminGetEntryListAPI = async () => {
    console.log('calling axios get entrys list (Admin)');
    const response = await apiGet({ url: `/admin/entry/list` });
    return response;
};

const updateEntryAPI = async ({ id, awardID, data }) => {
    const response = await apiUpdate({ url: `/entry?id=${id}&awardID=${awardID}`, data });
    return response;
};

const submitEntryAPI = async ({ id, data }) => {
    const response = await apiPost({ url: `/entry/submit?id=${id}`, data });
    return response;
};

const submitEntryAdminAPI = async ({ id, data }) => {
    const response = await apiPost({ url: `/entry/submit/admin?id=${id}`, data });
    return response;
};

const scoreEntryAPI = async ({ id, awardID, categoryID, data, submit }) => {
    const url = submit
        ? `/entry/score?id=${id}&awardID=${awardID}&categoryID=${categoryID}&submit=true`
        : `/entry/score?id=${id}&awardID=${awardID}&categoryID=${categoryID}`;
    const response = await apiPost({ url, data });
    return response;
};

const bestEntryAPI = async ({ id, awardID, data }) => {
    const url = `/entry/best?id=${id}&awardID=${awardID}`;
    const response = await apiPost({ url, data });
    return response;
};

const changeScoreStatusAPI = async ({ id, awardID, status }) => {
    const url = `/entry/score/status?entryID=${id}&awardID=${awardID}&status=${status}`;
    const response = await apiUpdate({ url });
    return response;
};

const deleteEntryAPI = async ({ id, awardID }) => {
    const url = `/entry?id=${id}&awardID=${awardID}`;
    const response = await apiDelete({ url });
    return response;
};

export {
    getEntryAPI,
    getEntryListAPI,
    createEntryAPI,
    updateEntryAPI,
    deleteEntryAPI,
    submitEntryAPI,
    submitEntryAdminAPI,
    adminGetEntryListAPI,
    scoreEntryAPI,
    changeScoreStatusAPI,
    bestEntryAPI
};
