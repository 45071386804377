// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconFileText } from '@tabler/icons';

// ===========================|| ENTRANT MENU OPTIONS ||=========================== //

const entrant = {
    id: 'entrant-menu',
    title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/entrant',
            icon: IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'entries-page',
            title: <FormattedMessage id="entries-page" />,
            type: 'item',
            url: '/entrant/entry/list',
            icon: IconFileText,
            breadcrumbs: false
        }
    ]
};

export default entrant;
