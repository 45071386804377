// third-party
import { configureStore } from '@reduxjs/toolkit';
import { loadState } from './persistState';
import accountReducer from './slices/accountSlice';
import menuReducer from './slices/menu';
import statusReducer from './slices/statusSlice';
import awardReducer from './slices/awardSlice';
import entryReducer from './slices/entrySlice';
import userReducer from './slices/userSlice';
import judgeReducer from './slices/judgeSlice';

// ==============================|| REDUX - MAIN STORE ||============================== //

const persistedState = loadState();

const store = configureStore({
    reducer: {
        menu: menuReducer,
        status: statusReducer,
        award: awardReducer,
        entry: entryReducer,
        user: userReducer,
        account: accountReducer,
        judge: judgeReducer
    },
    preloadedState: persistedState
});

export default store;
