// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconAward,
    IconCoinPound,
    IconDashboard,
    IconFileDollar,
    IconFileText,
    IconHelp,
    IconTrophy,
    IconUserCheck,
    IconUsers
} from '@tabler/icons';

// ==============================|| ADMIN MENU ITEMS ||============================== //

const admin = {
    id: 'admin-menu',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/',
            icon: IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'awards-page',
            title: <FormattedMessage id="awards-page" />,
            type: 'item',
            url: '/awards',
            icon: IconAward,
            breadcrumbs: false
        },
        {
            id: 'entries-page',
            title: <FormattedMessage id="entries-page" />,
            type: 'item',
            url: '/entries',
            icon: IconFileText,
            breadcrumbs: false
        },
        {
            id: 'categories-page',
            title: <FormattedMessage id="categories-page" />,
            type: 'item',
            url: '/categories',
            icon: IconTrophy,
            breadcrumbs: false
        },
        {
            id: 'judges-page',
            title: <FormattedMessage id="judges-page" />,
            type: 'item',
            url: '/judges',
            icon: IconUserCheck,
            breadcrumbs: false
        },
        {
            id: 'invoices-page',
            title: <FormattedMessage id="invoices-page" />,
            type: 'item',
            url: '/invoices',
            icon: IconFileDollar,
            breadcrumbs: false
        },
        {
            id: 'pricing-page',
            title: <FormattedMessage id="pricing-page" />,
            type: 'item',
            url: '/prices',
            icon: IconCoinPound,
            breadcrumbs: false
        },
        {
            id: 'users-page',
            title: <FormattedMessage id="users-page" />,
            type: 'item',
            url: '/users',
            icon: IconUsers,
            breadcrumbs: false
        },
        {
            id: 'sample-page2',
            title: 'Play Area',
            type: 'item',
            url: '/page2',
            icon: IconHelp,
            breadcrumbs: false
        }
    ]
};

export default admin;
