// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconFileText } from '@tabler/icons';

// ===========================|| ENTRANT MENU OPTIONS ||=========================== //

const judge = {
    id: 'judge-menu',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/judge',
            icon: IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'entry-page',
            title: <FormattedMessage id="entries" />,
            type: 'item',
            url: '/judge/entries',
            icon: IconFileText,
            breadcrumbs: false
        }
        // {
        //     id: 'sample-page2',
        //     title: 'Play Area',
        //     type: 'item',
        //     url: '/judge/play',
        //     icon: IconHelp,
        //     breadcrumbs: false
        // }
    ]
};

export default judge;
