import { apiGet } from './apiClient';

const getJudgeAPI = async (id) => {
    const response = await apiGet(`/judge?id=${id}`);
    return response;
};

const getJudgeListAPI = async ({ awardID }) => {
    console.log('calling axios get jugdes list');
    const response = await apiGet({ url: `/judge?awardID=${awardID}` });
    return response;
};

export { getJudgeAPI, getJudgeListAPI };
