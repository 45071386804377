import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getJudgeListAPI, getJudgeAPI } from 'APIs/judge';

// ==============================|| REDUX STORE: JUDGE DATA ||============================== //

const GET_JUDGE = createAsyncThunk('judge/get', async ({ id }) => {
    const response = await getJudgeAPI({ id });
    return response;
});

const GET_JUDGE_LIST = createAsyncThunk('judge/list', async ({ awardID }) => {
    const response = await getJudgeListAPI({ awardID });
    return response;
});

const initialState = {
    initialized: false,
    judges: []
};

const judgeSlice = createSlice({
    name: 'judge',
    initialState,
    reducers: {
        create(state, action) {
            state.judges.push(action.payload.data);
        },
        logout(state) {
            state.initialized = false;
            state.judges = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GET_JUDGE.fulfilled, (state, action) => {
                state.judges = state.judges.map((judge) => {
                    if (judge.id === action.payload.id) {
                        return { ...judge, ...action.payload };
                    }
                    return judge;
                });
            })
            .addCase(GET_JUDGE_LIST.fulfilled, (state, action) => {
                // TODO: This replaces the judge list for each award - better to include data for multiple awards
                state.judges = [...action.payload];
                state.initialized = true;
            });
    }
});

const { logout } = judgeSlice.actions;
const initialized = (state) => state?.judge?.initialized;
const judges = (state) => state?.judge?.judges || [];

export { GET_JUDGE, GET_JUDGE_LIST, initialized, judges, logout };
export default judgeSlice.reducer;
