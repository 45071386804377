import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import JudgeGuard from 'utils/route-guard/JudgeGuard';

// sample page routing
const DashboardJudge = Loadable(lazy(() => import('views/dashboard/judge')));
const JudgeEntryList = Loadable(lazy(() => import('views/judge/JudgeEntryList')));
const JudgeEntryDetail = Loadable(lazy(() => import('views/judge/JudgeEntryDetail')));
const UserSettings = Loadable(lazy(() => import('views/user-settings')));
const JudgeNDA = Loadable(lazy(() => import('views/judge/JudgeNDA')));
const PlayPage = Loadable(lazy(() => import('views/sample-page2')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/judge',
    element: (
        <JudgeGuard>
            <MainLayout />
        </JudgeGuard>
    ),
    children: [
        {
            path: '/judge',
            element: <DashboardJudge />
        },
        {
            path: '/judge/entries',
            element: <JudgeEntryList />
        },
        {
            path: '/judge/play',
            element: <PlayPage />
        },
        {
            path: '/judge/entry',
            element: <JudgeEntryDetail />
        },
        {
            path: '/judge/settings',
            element: <UserSettings />
        },
        {
            path: '/judge/nda',
            element: <JudgeNDA />
        }
    ]
};

export default MainRoutes;
