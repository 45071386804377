import { apiGet, apiUpdate, apiPost, apiDelete } from './apiClient';

const getAwardSummaryAPI = async ({ id }) => {
    const response = await apiGet({ url: `/award/summary?id=${id}` });
    return response;
};

const createAwardAPI = async ({ data }) => {
    const response = await apiPost({ url: `/award`, data });
    return response;
};

const getAwardListAPI = async () => {
    console.log('calling axios get awards list');
    const response = await apiGet({ url: `/award/list` });
    return response;
};

const updateAwardAPI = async ({ id, data }) => {
    const response = await apiUpdate({ url: `/award?id=${id}`, data });
    return response;
};

const deleteAwardAPI = async ({ id }) => {
    const response = await apiDelete({ url: `/award?id=${id}` });
    return response;
};

const deleteAwardCategoryAPI = async ({ awardID, categoryID }) => {
    const response = await apiDelete({ url: `/award/category?awardID=${awardID}&categoryID=${categoryID}` });
    return response;
};

export { getAwardListAPI, createAwardAPI, updateAwardAPI, deleteAwardAPI, getAwardSummaryAPI, deleteAwardCategoryAPI };
